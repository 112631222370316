@import './variables';
@import './mixins';

@mixin apply-main-styles {
    @each $space, $value in $spaces {
        @include apply-spacing($space, $value)
    }

    :root {
        @each $space, $value in $spaces {
            @include apply-spacing-var($space, $value)
        }
    }
}

.text-center {
    text-align: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}