@import '../../../scss/abstracts//mixins';
$sizes: (
    '9xl',
    '8xl',
    '7xl',
    '6xl',
    '5xl',
    '4xl',
    '3xl',
    '2xl',
    'xl',
    'lg',
    'base',
    'sm',
    'xs'
);

$weights: (
    'light',
    'regular',
    'medium',
    'semibold',
    'bold'
);

.text {
    font-size: var(--text-size-base);
    line-height: var(--text-height-base);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    text-decoration: none;
    @include apply-coloring();

    &__size {
        @each $size in $sizes {
            &--#{$size} {
                font-size: var(--text-size-#{$size});
                line-height: var(--text-height-#{$size});
            }
        }
    }

    &__weight {
        @each $weight in $weights {
            &--#{$weight} {
                font-weight: var(--text-weight-#{$weight});
            }
        }
    }
}