@import '../../../scss/abstracts/mixins.scss';

.creditContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 300px;
    height: 56px;
    border-radius: 8px;
    margin-right: -36px;
    background: linear-gradient(66.27deg, #002481 -6.25%, #1FAEFF 95.46%);
    &__textBlock {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &__purchase {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            &> button {
                width: 16px !important;
                height: 16px !important;
                fill: #ffffff !important;
                > svg {
                    fill: unset !important;
                }
            }
        }
    }
}