@import '../../../scss/abstracts/mixins.scss';

.navbar {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: var(--layout-border);
    background-color: var(--general-background-color);
    height: 68px;
    box-sizing: border-box;
    padding: 21.5px 24px;

    &__back {
        justify-content: start;

        div {
            width: unset;
        }

        &__button {
            width: 50px !important;
            height: 50px !important;
            box-sizing: border-box;

            svg {
                width: 28px !important;
                height: 28px !important;
                path {
                    fill: var(--color-blue-500) !important;
                }
            }

            &:hover {
                background-color: #D3DFE9 !important;
                box-shadow: none;
            }
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            height: 24px;
        }
    }

    &__breadcrumbs {
        display: flex;

        span {
            height: 36px;
            line-height: 36px;
        }

        &__list {
            padding-top: 2px;
            padding-left: 8px;

            &::before {
                content: '●';
                padding-right: 8px;
                color: var(--color-light-blue-1000);
            }
        }
    }

    button {
        justify-self: start;
        margin-left: 0px;
        margin-right: calc(50% - 116px);
    }

    &__explore {
        color: var(--color-light-blue);

        ::before {
            content: '\2022';
            @include spacing('me-3');
        }

        flex: 1;

    }
}