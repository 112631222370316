@import '../../../scss/abstracts/mixins.scss';

$sizes: (
    'medium',
    'large',
    'xlarge'
);

.loading {
    @include spacing(('w-full', 'w-full'));
    &__field {
        position: relative !important;
        overflow: hidden;
        pointer-events: none;
        cursor: not-allowed;
        &__screen {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index: 1000;

        }
    }
    &__size {
        @each $size in $sizes {
            &--#{$size} {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
                backdrop-filter: blur(2px);
                // background-color: var(--loading-background);
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                &>svg {
                    width: var(--loading-#{$size});
                    height: var(--loading-#{$size});
                }
            }
        }
    }
}