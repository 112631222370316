@import './vendors/_colors.scss';
@import './vendors/_variables.scss';
@import './vendors/_fonts.scss';


@mixin apply-light-theme {
  :root {
    --font-family: #{$font-family-plus-jakarta-sans};

    &[data-theme='light'] {

      @each $color, $value in $colors {
        --#{$color}: #{$value};
      }

      @each $breakpoint, $value in $breakpoints {
        --breakpoint-#{$breakpoint}: #{$value};
      }

      @each $height, $value in $textHeights {
        --#{$height}: #{$value};
      }

      @each $size, $value in $textSizes {
        --#{$size}: #{$value};
      }

      @each $weight, $value in $textWeights {
        --#{$weight}: #{$value};
      }

      @each $box, $value in $boxStyles {
        --#{$box}: #{$value};
      }
    }
  }
}