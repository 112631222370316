@import './themes/light.scss';
@import './abstracts/main';

@include apply-light-theme;
@include apply-main-styles;

html {
    height: 100vh;
    width: 100vw;
}

body {
    font-family: var(--font-family);
    margin: 0px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @include apply-scroll();

}

body {
    div {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 400;
    }
}

#root,
.home {
    height: 100%;
}

input {
    background-color: var(--color-white-1000);
}

.ant-picker-range {
    padding-right: 24px;

}

.ant-picker-range {
    .ant-picker-active-bar {
        background: var(--color-blue-1000) !important;
    }
}

.ant-picker-panel { 
    .ant-picker-cell-in-view {
        .ant-picker-cell {
            color: #B5BFD1 !important;
            background: var(--color-blue-1000) !important;
        }
    }
}

.ant-picker-panels, .ant-picker-panel {
    font-family: "Plus Jakarta Sans", sans-serif !important;

    .ant-picker-header {
        color: #2E3646 !important;

        :hover {
            color: var(--color-blue-1000) !important;
        }
    }

    .ant-picker-cell {
        color: #B5BFD1 !important;
    }

    .ant-picker-cell-in-view {
        color: #2E3646 !important;
    }

    .ant-picker-cell-in-range {
        &::before {
            background: #E9EFF4 !important;
        }

        .ant-picker-cell-inner {
            background: #E9EFF4 !important;
        }
    }

    .ant-picker-cell-today {
        .ant-picker-cell-inner {
            &::before {
                border-color: var(--color-blue-1000) !important;
            }
        }
    }

    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end {
        &::before {
            background: #E9EFF4 !important;
        }

        .ant-picker-cell-inner {
            background: var(--color-blue-1000) !important;
        }
    }
}