@import '../../../scss/abstracts/mixins';

.public {
    &__container {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/images/background.jfif');
        background-color: var(--background-color);
        background-size: cover;
        background-position-x: var(--length-0);
        background-position-y: var(--length-36);
        background-repeat: no-repeat;

    }

    &__back {
        position: fixed;
        @include spacing(('t-5', 'l-32'));

        &__button {
            font-size: var(--text-size-base);
            font-weight: var(--text-weight-medium) !important;
        }
    }

    &__language {
        position: fixed;
        @include spacing(('t-5', 'r-32'));
        display: flex;
        flex-direction: row;
        align-items: center;

        &__button {
            font-size: var(--text-size-base);
            font-weight: var(--text-weight-medium);
        }
    }
}

.bottom__logo {
    position: fixed;
    bottom: 80px;
    &> svg {
        & > filled {
            color: white;
        }
    }
}

@media screen and (max-height: 970px) {
    .bottom__logo {
        display: none;
    }
}

@media screen and (max-width: 426px) {
    .public {
        &__language {
            position: fixed;
            @include spacing(('r-10'));
        }
    }
}