@import '../../../scss/abstracts/mixins.scss';

.side__menu {
    grid-row: 2;
    grid-column: 1;
    @include column-flex();
    @include spacing(('t-24'));
    justify-content: start;
    border-right: var(--layout-border);
    box-sizing: border-box;
    width: 100px;

    &__flex {
        flex: 1;
    }

    &__language {
        display: flex;
        flex-direction: column;

        button {
            height: 18px;
            @include spacing(('mb-2'));
        }

    }

    &--open {
        @include spacing(('py-8', 'px-6', 'w-48'));
    }

    &--closed {
        @include spacing(('py-8', 'w-24'));
    }

    &__logout {
        background-color: transparent !important;
        border: none !important;
        width: 60px !important;
        height: 60px !important;
        @include spacing(('mb-6', 'mt-2'));
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            @include spacing(('w-7', 'h-7'));

            path {
                fill: var(--color-blue-500) !important;
            }
        }

        &:hover {
            background-color: transparent !important;
            box-shadow: none;
        }
    }

    &__button {
        width: 60px !important;
        height: 60px !important;
        @include spacing(('mb-4'));

        svg {
            @include spacing(('w-7', 'h-7'));
        }


        &[aria-selected="true"] {
            box-shadow: none;
            svg {
                path {
                    fill: var(--color-blue-1000) !important;
                }
                rect {
                    fill: var(--color-blue-1000) !important;
                }
            }
        }

        &[aria-selected="false"] {
            svg {
                path {
                    fill: var(--color-blue-500) !important;
                }
                rect {
                    fill: var(--color-blue-500) !important;
                }
            }
        }

        &:hover {
            background-color: var(--color-blue-200) !important;
            box-shadow: none;
        }
    }
}