@import '../../../scss/abstracts/mixins.scss';

$sizes: (
    'small',
    'medium',
    'large'
);
$variants: (
    'primary',
    'secondary',
    'text'
);


.primary {
    @include apply-button-coloring();
}

.secondary {
    @include apply-button-secondary-coloring();
}

.text {
    @include apply-coloring();
}

.icon {
    @include apply-button-coloring();
}

.button {
    border-radius: var(--button-radius);
    background-color: var(--color-white-1000);
    box-shadow: none;
    border: var(--button-border-secondary);
    font-size: var(--text-size-xl);
    line-height: 150%;

    cursor: pointer;
    float: left;
    @include row-flex();
    justify-content: center;
    box-sizing: border-box;

    div {
        font-weight: var(--text-weight-semibold) !important;
    }

    svg {
        width: 20px !important;
        height: 20px !important;
    }

    &:disabled {
        cursor: not-allowed;
    }

    @each $variant in $variants {
        &__variant--#{$variant} {
            background-color: var(--color-button-#{$variant});
            border: var(--button-border-#{$variant});
            padding: var(--button-padding-#{$variant});
        }
    }

    &__variant--primary {
        &:disabled {
            background-color: var(--color-grey-1000) !important;
        }
    }

    &--loading {
        color: var(--color-red-500);
    }

    &:disabled {
        color: var(--color-red-500);
    }

    &__size {
        @each $size in $sizes {
            &--#{$size} {
                height: var(--button-#{$size}-height);
                font-size: var(--text-size-#{$size});
                padding: var(--button-padding-#{$size});
            }
        }
    }

    &__icon {
        border-radius: 50%;
        background-color: var(--color-gray-10);
        border: var(--button-border-icon);
        background-color: var(--color-white-1000);
        height: fit-content;
        line-height: 100% !important;

        &__size {
            @each $size in $sizes {
                &--#{$size} {
                    font-size: var(--text-size-#{$size});
                    padding: var(--icon-padding-#{$size});
                }
            }
        }

        &:focus,
        &:focus-visible,
        &:hover {
            outline: none !important;
            box-shadow: var(--box-shadow-lg);
        }

        &--passive {
            color: var(--color-light-blue-800) !important;

            >svg,
            svg>path {
                fill: var(--color-light-blue-800) !important;
                color: var(--color-light-blue-800) !important;
            }

            &:hover {
                color: var(--color-light-blue-800) !important;

                >svg,
                svg>path {
                    fill: var(--color-light-blue-800) !important;
                    color: var(--color-light-blue-800) !important;
                }
            }
        }

        &--disabled {
            background-color: var(--color-light-blue-800) !important;
            color: var(--color-white-1000) !important;
            pointer-events: none;
            cursor: not-allowed;

            >svg,
            svg>path {
                fill: var(--color-white-1000) !important;
                color: var(--color-white-1000) !important;
            }
        }
    }


    &--passive {
        color: var(--color-light-blue-800) !important;

        >svg,
        svg>path {
            fill: var(--color-light-blue-800) !important;
            color: var(--color-light-blue-800) !important;
        }

        &:hover {
            color: var(--color-light-blue-800) !important;

            >svg,
            svg>path {
                fill: var(--color-light-blue-800) !important;
                color: var(--color-light-blue-800) !important;
            }
        }
    }
}