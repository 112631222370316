@import '../../../scss/abstracts/mixins.scss';

.toast {

    animation: slide-in 0.4s ease-in-out forwards, slide-out 0.4s ease-in-out 5s forwards;
    @include spacing(('w-96'));
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-white-1000);
    border-radius: var(--border-radius-small);

    &__success {
        background-color: var(--toast-success);

        p {
            color: var(--color-dark-blue-1000) !important;
        }

        svg {
            >path {
                fill: var(--color-dark-blue-1000) !important;
            }
        }
    }

    &__error {
        background-color: var(--toast-error);

        p {
            color: var(--color-dark-blue-1000) !important;
        }

        svg {
            >path {
                fill: var(--color-dark-blue-1000) !important;
            }
        }
    }

    &__info {
        background-color: var(--toast-info);

        svg {
            >path {
                fill: var(--color-white-1000) !important;
            }
        }
    }

    &__warning {
        background-color: var(--color-dark-blue-1000);
    }

    &__message {
        flex: 1;
    }

    &__icon {
        >svg {
            font-size: var(--text-size-2xl);
            @include spacing(('w-6', 'h-6', 'mt-1'));

            >path {
                fill: var(--color-white-1000);
            }
        }
    }

    &__container {
        position: fixed;
        @include spacing(('t-0', 'r-0'));
        z-index: 1050;
    }

    &__undo {
        color: var(--color-white-1000) !important;
        font-size: var(--text-size-sm);
        @include spacing(('py-2', 'px-5'));
    }

    &__dismiss {
        >svg {
            >path {
                fill: var(--color-white-1000);
            }
        }

        color: var(--color-white-1000) !important;
    }

}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}