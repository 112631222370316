$spacing-0: 0px;
$spacing-05: 2px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 28px;
$spacing-8: 32px;
$spacing-9: 36px;
$spacing-10: 40px;
$spacing-11: 44px;
$spacing-12: 48px;
$spacing-14: 56px;
$spacing-16: 64px;
$spacing-20: 80px;
$spacing-24: 96px;
$spacing-28: 112px;
$spacing-32: 128px;
$spacing-36: 144px;
$spacing-40: 160px;
$spacing-48: 192px;
$spacing-52: 208px;
$spacing-56: 224px;
$spacing-60: 240px;
$spacing-64: 256px;
$spacing-72: 288px;
$spacing-80: 320px;
$spacing-96: 384px;
$spacing-auto: auto;

$spaces: (
    '0': $spacing-0,
    '05': $spacing-05,
    '1': $spacing-1,
    '2': $spacing-2,
    '3': $spacing-3,
    '4': $spacing-4,
    '5': $spacing-5,
    '6': $spacing-6,
    '7': $spacing-7,
    '8': $spacing-8,
    '9': $spacing-9,
    '10': $spacing-10,
    '11': $spacing-11,
    '12': $spacing-12,
    '14': $spacing-14,
    '16': $spacing-16,
    '20': $spacing-20,
    '24': $spacing-24,
    '28': $spacing-28,
    '32': $spacing-32,
    '36': $spacing-36,
    '40': $spacing-40,
    '48': $spacing-48,
    '52': $spacing-52,
    '56': $spacing-56,
    '60': $spacing-60,
    '64': $spacing-64,
    '72': $spacing-72,
    '80': $spacing-80,
    '96': $spacing-96,
    'auto': $spacing-auto
);
$space-names: (
    'ms': ('margin-left'),
    'me': ('margin-right'),
    'mt': ('margin-top'),
    'mb': ('margin-bottom'),
    'my': ('margin-top', 'margin-bottom'),
    'mx': ('margin-left', 'margin-right'),
    'm': ('margin'),
    'ps': ('padding-left'),
    'pe': ('padding-right'),
    'pt': ('padding-top'),
    'pb': ('padding-bottom'),
    'py': ('padding-top', 'padding-bottom'),
    'px': ('padding-left', 'padding-right'),
    'p': ('padding'),
    'w': ('width'),
    'h': ('height'),
    't': ('top'),
    'r': ('right'),
    'b': ('bottom'),
    'l': ('left'),
    'minw': ('min-width'),
    'minh': ('min-height'),
    'maxw': ('max-width'),
    'maxh': ('max-height'),
);