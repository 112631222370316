@import '../../../scss/abstracts/mixins.scss';

.protected {
    &__container {
        background-color: var(--general-background-color);
        position: fixed;
        display: grid;
        grid-template-rows: 68px auto;
        grid-template-columns: 96px auto;
        height: 100vh;
        width: 100vw;
        &__without {
            grid-template-columns: 0px 100% !important;
        }

        &__page {
            @include apply-scroll();
            height: calc(100dvh - 68px);
            overflow: auto;
            grid-row: 2;
            grid-column: 2;
            box-sizing: border-box;
            &--noPadding {
                padding: 0 !important;
            }
        }

        &--open {
            .side__menu {
                @include spacing('w-60');
            }
        }

        &--closed {
            .side__menu {
                @include spacing('w-28');
            }
        }
    }
}