@import './variables';


@mixin card-style() {
    background-color: var(--color-white-1000);
    border-radius: var(--border-radius-small);
    box-shadow: var(--box-shadow-base);
}

@mixin apply-spacing($name, $value) {
    @each $space-name, $space-value in $space-names {
        .#{$space-name}-#{$name} {
            @each $s-value in $space-value {
                #{$s-value}: $value;
            }
        }
    }
}

$colors-of-text: (
    'primary',
    'dark-blue',
    'medium-blue',
    'extra-light',
    'light-blue',
    'white',
    'grey',
    'light-grey',
    'light',
    'blue',
    'turquoise',
    'yellow',
    'orange',
    'red',
    'green',
    'light-green',
    'purple',
    'pink',
    'natural',
    'crimson',
    'cream',
    'success',
    'error',
    'info'
);

@mixin apply-coloring() {
    @each $color in $colors-of-text {
        &__color--#{$color} {
            color: var(--color-#{$color}-1000);
        }
    }

    &__color--white-500 {
        color: var(--color-white-500);
    }

    &__color--white-300 {
        color: var(--color-white-300);
    }

    &__color--blue-500 {
        color: var(--color-blue-500);
    }

    &__color--blue-1000 {
        color: var(--color-blue-1000);
    }
}

$colors-of-button: (
    'primary': 'white',
    'dark-blue': 'white',
    'extra-light': 'dark-blue',
    'light-blue': 'dark-blue',
    'white': 'primary',
    'light-gray': 'dark-blue',
    'blue': 'white',
    'turquoise': 'white',
    'yellow': 'dark-blue',
    'orange': 'white',
    'red': 'white',
    'green': 'white',
    'light-green': 'white',
    'purple': 'white',
    'grey': 'white',
    'transparent': 'none'
);

$borders-of-button: (
    'primary': 'white',
    'dark-blue': 'white',
    'extra-light': 'dark-blue',
    'light-blue': 'dark-blue',
    'white': 'blue',
    'light-gray': 'dark-blue',
    'blue': 'white',
    'turquoise': 'white',
    'yellow': 'dark-blue',
    'orange': 'white',
    'red': 'white',
    'green': 'white',
    'light-green': 'white',
    'purple': 'white',
    'grey': 'grey',
    'transparent': 'none'
);

$hovers-of-button: (
    'primary': '--color-navy-1000',
    'dark-blue': '--color-primary-1000',
    'extra-light': '--color-dark-blue-1000',
    'light-blue': '--color-dark-blue-1000',
    'white': '--color-blue-100',
    'light-gray': '--color-dark-blue-1000',
    'blue': '--color-white-1000',
    'turquoise': '--color-white-1000',
    'yellow': '--color-dark-blue-1000',
    'orange': '--color-white-1000',
    'red': '--color-white-1000',
    'green': '--color-white-1000',
    'light-green': '--color-white-1000',
    'purple': '--color-white-1000',
    'grey': '--color-grey-1000',
    'transparent': 'dark-blue'
);

@mixin apply-button-coloring() {
    @each $color, $value in $colors-of-button {
        &__color--#{$color} {
            color: var(--color-#{$value}-1000) !important;
            background-color: var(--color-#{$color}-1000) !important;

            &:hover {
                background-color: var(#{map-get($hovers-of-button, $color)}) !important;
                color: var(--color-#{$value}-1000) !important;


                >svg,
                svg>path {
                    fill: var(--color-#{$value}-1000) !important;
                    color: var(--color-#{$value}-1000) !important;
                }
            }

            >svg,
            svg>path {
                fill: var(--color-#{$value}-1000) !important;
                color: var(--color-#{$value}-1000) !important;
            }
        }
    }
}

@mixin apply-button-secondary-coloring() {
    @each $color, $value in $colors-of-button {
        &__color--#{$color} {
            color: var(--color-#{$value}-1000) !important;
            border-color: var(--color-#{map-get($borders-of-button, $color)}-1000) !important;

            &:hover {
                border-color: var(--color-#{map-get($borders-of-button, $color)}-1000) !important;
                color: var(--color-#{$value}-1000) !important;
                background-color: var(#{map-get($hovers-of-button, $color)}) !important;

                >svg,
                svg>path {
                    fill: var(--color-#{$value}-1000) !important;
                    color: var(--color-#{$value}-1000) !important;
                }
            }

            >svg,
            svg>path {
                fill: var(--color-#{$value}-1000) !important;
                color: var(--color-#{$value}-1000) !important;
            }
        }
    }
}


@mixin row-flex() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &__flex {
        flex: 1;
    }

}

@mixin column-flex() {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@mixin spacing($directions) {
    @each $dir in $directions {
        $first-part: str-slice($dir, 1, str-index($dir, '-') - 1);
        $second-part: str-slice($dir, str-index($dir, '-') + 1);
        $spaces-of: map-get($space-names, $first-part);

        @each $s in $spaces-of {
            #{$s}: map-get($spaces, $second-part) !important;
        }
    }
}

@mixin apply-spacing-var($name, $value) {
    --length-#{$name}: #{$value};
}

@mixin apply-scroll {
    &::-webkit-scrollbar {
        width: var(--border-radius-medium);
        height: var(--border-radius-medium);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-blue-200);
        border-radius: var(--border-radius-medium);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-blue-500);
        border-radius: var(--border-radius-medium);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--color-blue-700);
    }
}